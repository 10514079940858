<template>
  <v-card class="flex-grow-1">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Monthly Summary</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="3" class="d-flex align-center">
          <v-avatar size="44" :color="resolveStatisticsIconVariation(data.title).color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ resolveStatisticsIconVariation(data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ml-3">
            <p class="mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent } from '@/plugins/composition';
import { mdiAccountOutline, mdiCurrencyUsd, mdiDotsVertical, mdiLabelOutline, mdiTrendingUp } from '@mdi/js';

export default defineComponent({
  name: 'SummaryCard',
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const statisticsData = [
      {
        title: 'Consults',
        total: props.summary.consults,
      },
      {
        title: 'Emails',
        total: props.summary.emails,
      },
      {
        title: 'Messages',
        total: props.summary.messages,
      },
    ];

    const resolveStatisticsIconVariation = (data) => {
      if (data === 'Sales') return { icon: mdiTrendingUp, color: 'primary' };
      if (data === 'Customers') return { icon: mdiAccountOutline, color: 'success' };
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' };
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' };

      return { icon: mdiAccountOutline, color: 'success' };
    };

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    };
  },
});
</script>
