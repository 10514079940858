











































import { Client, Site } from '@/models';
import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';

export default defineComponent({
  name: 'MultiSiteSelect',
  props: {
    client: {
      type: Object as PropType<Client>,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<Site[]>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const sites = computed({
      get: () => props.value,
      set: (sites: Site[]) => emit('input', sites),
    });

    const allSites = ref<Site[]>([]);

    const setSites = (input: Site[]) => {
      sites.value = input;
    };

    const loading = ref(false);
    const fetchSites = async (): Promise<void> => {
      if (!props.client) {
        return;
      }
      loading.value = true;
      allSites.value = await props.client.sites().getAll();
      loading.value = false;
    };

    const toggle = () => {
      if (sites.value.length === allSites.value.length) {
        sites.value = [];
      } else {
        sites.value = allSites.value;
      }
    };

    const icon = computed(() => {
      if (sites.value.length === allSites.value.length) return 'mdi-close-box';
      if (sites.value.length > 0) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    });

    watch(
      () => props.client,
      async () => {
        sites.value = [];
        allSites.value = [];
        await fetchSites();
        sites.value = allSites.value;
      }
    );

    return {
      fetchSites,
      loading,
      sites,
      allSites,
      toggle,
      icon,
      setSites,
    };
  },
});
