





import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { PieChart } from 'vue-chart-3';
import { PropType, defineComponent, ref } from '@/plugins/composition';
import { capitalizeFirstLetter } from '@/filters/capitalize';
import { colours, shuffle } from './colours';

Chart.register(...registerables);

type ReasonSummaryItem = {
  label: string;
  value: number;
};

export default defineComponent({
  name: 'BasePieChart',
  components: { PieChart },
  props: {
    data: {
      type: Array as PropType<ReasonSummaryItem[]>,
      required: true,
    },
  },
  setup(props) {
    const data = props.data.map((value) => {
      return {
        label: value.label,
        value: value.value,
      };
    });

    const chartData = ref<ChartData<'pie'>>({
      labels: data.map((item) => capitalizeFirstLetter(item.label)),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: shuffle(colours),
        },
      ],
    });

    const options = ref<ChartOptions<'pie'>>({
      plugins: {
        legend: {
          position: 'right',
        },
      },
    });

    return { chartData, options };
  },
});
