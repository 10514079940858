




import { BarChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { PropType, computed, defineComponent, ref } from '@/plugins/composition';
import { formatDate } from '@/utils';

Chart.register(...registerables);

export default defineComponent({
  name: 'StackedColumnChart',
  components: { BarChart },
  props: {
    data: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  setup(props) {
    const dates = computed(() => props.data.map((item) => formatDate(new Date(item.month), 'MM/yy')));

    const sets = computed(() => {
      let fooData: { [key: string]: any } = {};

      props.data.map((item) => {
        item.metrics.map((metric: any) => {
          if (!fooData[metric.indicator.label]) {
            fooData[metric.indicator.label] = {
              results: [],
              colour: metric.indicator.colour,
              order: metric.indicator.order,
            };
          }
          fooData[metric.indicator.label].results.push({ month: item.month, value: metric.count });
        });
      });

      for (const [key, value] of Object.entries(fooData)) {
        props.data.map((item) => {
          if (!value.results.find((value: any) => value.month === item.month)) {
            fooData[key].results.push({ month: item.month, value: 0 });
          }
        });
      }

      for (const [key, value] of Object.entries(fooData)) {
        value.results.sort(
          (a: any, b: any) =>
            dates.value.indexOf(formatDate(new Date(a.month), 'MM/yy')) -
            dates.value.indexOf(formatDate(new Date(b.month), 'MM/yy'))
        );
      }

      const data = [];

      for (const [key, value] of Object.entries(fooData)) {
        data.push({
          label: key,
          data: value.results.map((item: any) => item.value),
          backgroundColor: value.colour,
          order: value.order,
        });
      }

      return data;
    });

    const testData = ref<ChartData<'bar'>>({
      datasets: sets.value,
      labels: dates.value,
    });

    const options = ref<ChartOptions<'bar'>>({
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    });

    return { testData, options, dates, sets };
  },
});
