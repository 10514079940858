























import BasePieChart from '../../metrics/components/BasePieChart.vue';
import { DataTableHeader } from 'vuetify';
import { PropType, computed, defineComponent } from '@/plugins/composition';

type ReasonSummaryItem = {
  label: string;
  value: number;
};

export default defineComponent({
  name: 'CategorySummaryCard',
  props: {
    data: {
      type: Array as PropType<ReasonSummaryItem[]>,
      required: true,
    },
  },
  components: {
    BasePieChart,
  },
  setup(props) {
    const tableData = computed(() => {
      const total = props.data.reduce((a, b) => a + b.value, 0);

      return props.data.map((value) => {
        return {
          label: value.label,
          value: value.value,
          percent: Math.round((value.value / total) * 100),
        };
      });
    });

    const tableHeaders: DataTableHeader[] = [
      { text: 'Category', value: 'label', align: 'center', cellClass: 'category' },
      { text: 'Number', value: 'value', align: 'center' },
      { text: '%', value: 'percent', align: 'center' },
    ];
    return {
      tableHeaders,
      tableData,
    };
  },
});
