






























































































import CategorySummaryCard from '../components/CategorySummaryCard.vue';
import ClientCard from '../components/ClientCard.vue';
import ClientSelectInput from '@/components/inputs/ClientSelectInput.vue';
import DateTimePicker from '@/components/inputs/DateTimePicker.vue';
import DateTimeRangePicker from '@/components/inputs/DateTimeRangePicker.vue';
import MetricIndicatorCard from '../components/MetricIndicatorCard.vue';
import MultiSiteSelect from '@/components/inputs/MultiSiteSelect.vue';
import ReasonSummaryCard from '../components/ReasonSummaryCard.vue';
import SummaryCard from '../components/SummaryCard.vue';
import endOfMonth from 'date-fns/fp/endOfMonth';
import endOfQuarter from 'date-fns/fp/endOfQuarter';
import startOfMonth from 'date-fns/startOfMonth/index';
import startOfQuarter from 'date-fns/startOfQuarter/index';
import { Client, Site } from '@/models';
import { defineComponent, ref } from '@/plugins/composition';
import { endOfYear, formatISO, startOfYear, subMonths } from 'date-fns';

export default defineComponent({
  name: 'ClientReportPage',
  components: {
    ClientSelectInput,
    DateTimeRangePicker,
    DateTimePicker,
    SummaryCard,
    ClientCard,
    ReasonSummaryCard,
    CategorySummaryCard,
    MultiSiteSelect,
    MetricIndicatorCard,
  },
  setup() {
    const start = ref(startOfMonth(subMonths(new Date(), 1)));
    const end = ref(endOfMonth(subMonths(new Date(), 1)));
    const client = ref<Client | null>(null);
    const sites = ref<Site[]>([]);
    const loading = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const report = ref<any>(null);

    const refresh = async () => {
      loading.value = true;
      report.value = await client.value?.report(
        formatISO(start.value as Date),
        formatISO(end.value as Date),
        sites.value
      );
      loading.value = false;
    };

    const preset = (preset: 1 | 2 | 3 | 4 | 5) => {
      if (preset === 1) {
        start.value = startOfMonth(new Date());
        end.value = endOfMonth(new Date());
      }

      if (preset === 2) {
        start.value = startOfMonth(subMonths(new Date(), 1));
        end.value = endOfMonth(subMonths(new Date(), 1));
      }

      if (preset === 3) {
        start.value = startOfYear(new Date());
        end.value = endOfYear(new Date());
      }

      if (preset === 4) {
        start.value = startOfQuarter(subMonths(new Date(), 3));
        end.value = endOfQuarter(subMonths(new Date(), 3));
      }

      if (preset === 5) {
        start.value = startOfQuarter(new Date());
        end.value = endOfQuarter(new Date());
      }
    };

    return {
      start,
      end,
      refresh,
      client,
      sites,
      report,
      preset,
      loading,
    };
  },
});
