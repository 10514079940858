























import StackedColumnChart from '../../metrics/components/StackedColumnChart.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'MetricIndicatorCard',
  components: {
    StackedColumnChart,
  },
  props: {
    data: {
      type: Object,
    },
  },

  setup(props) {
    const chartData = props.data?.results;

    return {
      chartData,
    };
  },
});
