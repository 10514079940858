


























import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'ClientCard',
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
});
