









































import { PropType, computed, defineComponent, onMounted, ref, watch } from '@/plugins/composition';
import { addMinutes } from 'date-fns';
import { dateFromDateTimeString, formatDate, formatTime, makeDate, roundTimeMinutes } from '@/utils';

export default defineComponent({
  name: 'DateTimePicker',

  props: {
    value: {
      type: [Date, String] as PropType<Date | string>,
      required: false,
      default: null,
    },
    interval: {
      type: [Number],
      required: false,
      default: 1,
    },
    min: {
      type: [Date, String] as PropType<Date | string>,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    startOfDay: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Date',
    },
  },

  setup(props, { emit }) {
    const timeValue = computed({
      get: () => formatDate(props.value, 'HH:mm'),
      set: (value) => {
        emitValue(dateValue.value, value);
      },
    });
    const dateValue = computed({
      get: () => formatDate(props.value, 'yyyy-MM-dd'),
      set: (value) => {
        emitValue(value, timeValue.value);
      },
    });

    const emitValue = (date: string | null, time: string | null) => {
      emit('input', dateFromDateTimeString(date, time));
    };

    const allowedMinutes = (m: number) => m % props.interval === 0;

    const dateMenu = ref(false);

    const formattedDate = computed(() => formatDate(dateValue.value));
    const formattedTime = computed(() => formatTime(timeValue.value));

    const minTime = computed(() => (props.min ? formatDate(props.min, 'HH:mm') : null));
    const minDate = computed(() => (props.min ? formatDate(props.min, 'yyyy-MM-dd') : null));

    onMounted(() => {
      timeValue.value = roundTimeMinutes(timeValue.value, 15);
    });

    const formattedDateTime = computed(() => {
      if (!timeValue) {
        return formattedDate.value;
      }

      if (!dateValue) {
        return '';
      }

      const inputDate = formattedDate.value ?? '';
      const inputTime = formattedTime.value ?? '';

      return inputDate + (!props.dateOnly ? ' ' + inputTime : '');
    });

    watch(
      () => props.min,
      () => {
        if (props.min && makeDate(props.value) < makeDate(props.min)) {
          const date = makeDate(props.min);
          emit('input', addMinutes(date, props.interval ?? 15));
        }
      }
    );

    return {
      dateMenu,
      formattedDate,
      formattedDateTime,
      formattedTime,
      timeValue,
      allowedMinutes,
      dateValue,
      minTime,
      minDate,
    };
  },
});
